table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  display: block;
}

table thead tr {
  background-color: #009879;
  color: #ffffff;
}

thead,
tfoot,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table th,
table td {
  padding: 12px 15px;
}

tbody {
  display: block;
  max-height: 450px;
  overflow: auto;
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

tfoot tr {
  font-weight: bold;
  color: #009879;
}
