* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.rc-slider .rc-slider-track {
  background-color: hsl(204deg 8% 24%);
}

.rc-slider .rc-slider-handle {
  border-color: hsl(204deg 8% 24%);
}

.rc-slider .rc-slider-handle:focus {
  border-color: hsl(204deg 8% 24%);
  box-shadow: none;
  outline: none;
}
